import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as pages from 'pages'

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/Locations" component={pages.Locations} />
        <Route component={pages.Home} />
      </Switch>
    </Router>
  )
}
