import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, locationMap2, mobileMap, mobileMap2 } from 'images'
import { dispatch } from 'store'

const locationMaps = {
  Cdppq8wkAl0pYCCGrKCA:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_Guildford.jpg',
  Fhm09z7wYlt9dBuzI06y:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_UBC.jpg',
  TYe1YOOktKrvpckotSUu:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_New_West.jpg',
  lWoCbjf1UTpTpwoTv7Ij:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_Tsawwassen.jpg',
  ul2rsIuDM58PRwemR7tz: locationMap2,
  vjFb4cvIKMsQZJ3eHe9W:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_Metrotown.jpg',
  z7xiBbF4M6It88nAuRWo:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818585/togosushi/locationMap_Richmond.jpg',
  zQ8ACADtoGCQ7WdYpwiK: locationMap,
}

const locationMapsMobile = {
  Cdppq8wkAl0pYCCGrKCA:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818190/togosushi/mobileMap_Guildford.png',
  Fhm09z7wYlt9dBuzI06y:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818191/togosushi/mobileMap_UBC.png',
  TYe1YOOktKrvpckotSUu:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818191/togosushi/mobileMap_New_West.png',
  lWoCbjf1UTpTpwoTv7Ij:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818190/togosushi/mobileMap_Tsawwassen.png',
  ul2rsIuDM58PRwemR7tz: mobileMap2,
  vjFb4cvIKMsQZJ3eHe9W:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818190/togosushi/mobileMap_Metrotown.png',
  z7xiBbF4M6It88nAuRWo:
    'https://res.cloudinary.com/codefusiontech/image/upload/v1590818190/togosushi/mobileMap_Richmond.png',
  zQ8ACADtoGCQ7WdYpwiK: mobileMap,
}

const locationMapsUrl = {
  Cdppq8wkAl0pYCCGrKCA: 'https://goo.gl/maps/XmRoqFf7Wt57onwZA',
  Fhm09z7wYlt9dBuzI06y: 'https://goo.gl/maps/RgDf9QCqgL9em3J39',
  TYe1YOOktKrvpckotSUu: 'https://goo.gl/maps/5sY8S4CfrwbQ89X6A',
  lWoCbjf1UTpTpwoTv7Ij: 'https://goo.gl/maps/Rg4vN2Y3ndMMvxr36',
  ul2rsIuDM58PRwemR7tz: 'https://goo.gl/maps/YmASyGm2n5LtM5ah6',
  vjFb4cvIKMsQZJ3eHe9W: 'https://goo.gl/maps/B9QKPX7HJUx1Chyu9',
  z7xiBbF4M6It88nAuRWo: 'https://goo.gl/maps/9AswkYsWvWhZH1kh6',
  zQ8ACADtoGCQ7WdYpwiK: 'https://goo.gl/maps/Mq6YeRZQe7nrHTez9',
}

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <CFLink href={locationMapsUrl[locationId]}>
              <CFImage
                src={locationMapsMobile[locationId]}
                w="100%"
                alt="Togo Sushi Map"
              />
            </CFLink>
          </MobileScreen>
          <DefaultScreen>
            <CFLink href={locationMapsUrl[locationId]} newTab>
              <CFImage
                src={locationMaps[locationId]}
                w="100%"
                cover
                alt="Togo Sushi Map"
              />
            </CFLink>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
