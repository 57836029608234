import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column center>
          <CFView h2 futura bold color="#FF9F00" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white bold>
            <CFView maxWidth="300px" textCenter fontSize="20px" mb="15px">
              Togo Sushi is now taking online orders. Order through our website
              or download our app in your app store!
            </CFView>
            <CFView row w="100%" justifyAround>
              <CFLink href="https://apps.apple.com/us/app/togo-sushi/id1510210209?ls=1">
                <CFImage h="42px" src={appStore} alt="Togo Sushi App Store" />
              </CFLink>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.togosushi">
                <CFImage
                  h="42px"
                  src={googlePlay}
                  alt="Togo Sushi Google Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h2 futura bold color="#FF9F00" mb="20px" minWidth="200px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white bold>
            <CFView maxWidth="260px" fontSize="16px" mb="15px">
              Togo Sushi is now taking online orders. Order through our website
              or download our app in your app store!
            </CFView>
            <CFLink
              href="https://apps.apple.com/us/app/togo-sushi/id1510210209?ls=1"
              newTab
            >
              <CFImage
                h="40px"
                mt="5px"
                src={appStore}
                alt="Togo Sushi App Store"
                hover
              />
            </CFLink>
            <CFLink
              href="https://play.google.com/store/apps/details?id=tech.codefusion.togosushi"
              newTab
            >
              <CFImage
                h="40px"
                mt="5px"
                src={googlePlay}
                alt="Togo Sushi Google Play"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
