import React from 'react'
import { Card, CFIcon, CFView } from 'components'

export default ({ name, address, onClick }) => (
  <Card
    mb={20}
    h={75}
    br={8}
    ph={19}
    w="100%"
    row
    alignCenter
    hover
    onClick={onClick}
  >
    <CFIcon icon="map-marker-alt" color="black" fontSize={30} />
    <CFView ml={20} fill>
      <CFView h4 bold color="black" mb="3px">
        {name}
      </CFView>
      <CFView h6 color="black">
        {address}
      </CFView>
    </CFView>
  </Card>
)
