import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton, viewMenuButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect
      selector={{
        locationId: dispatch.restaurant.getSelectedLocationId,
        pdfMenuUrl: dispatch.restaurant.getPdfMenuUrl,
      }}
    >
      {({ locationId, pdfMenuUrl }) => {
        let orderButtonLink = null
        let orderButton = null
        if (locationId === 'vjFb4cvIKMsQZJ3eHe9W') {
          orderButtonLink = pdfMenuUrl
          orderButton = viewMenuButton
        } else if (locationId === 'lWoCbjf1UTpTpwoTv7Ij') {
          orderButtonLink = pdfMenuUrl
          orderButton = viewMenuButton
        } else {
          orderButtonLink = `https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/${locationId}`
          orderButton = orderPickupButton
        }
        return (
          <CFView>
            <MobileScreen>
              <a href={orderButtonLink}>
                <CFView hover>
                  <CFImage src={orderButton} maxWidth="300px" alt="About" />
                </CFView>
              </a>
            </MobileScreen>
            <DefaultScreen>
              <a href={orderButtonLink}>
                <CFView hover>
                  <CFImage src={orderButton} maxWidth="400px" alt="About" />
                </CFView>
              </a>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
