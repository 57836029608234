import React from 'react'
import {
  CFLink,
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { downloadAppButton, hero, heroText, mobileHero, viewMenu } from 'images'
import { dispatch } from 'store'

const locationMenus = {
  Cdppq8wkAl0pYCCGrKCA:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/Cdppq8wkAl0pYCCGrKCA',
  Fhm09z7wYlt9dBuzI06y:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/Fhm09z7wYlt9dBuzI06y',
  TYe1YOOktKrvpckotSUu:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/TYe1YOOktKrvpckotSUu',
  lWoCbjf1UTpTpwoTv7Ij:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/lWoCbjf1UTpTpwoTv7Ij',
  ul2rsIuDM58PRwemR7tz:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/ul2rsIuDM58PRwemR7tz',
  vjFb4cvIKMsQZJ3eHe9W:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/vjFb4cvIKMsQZJ3eHe9W',
  z7xiBbF4M6It88nAuRWo:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/z7xiBbF4M6It88nAuRWo',
  zQ8ACADtoGCQ7WdYpwiK:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/zQ8ACADtoGCQ7WdYpwiK',
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          w="100%"
          bg="white"
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
          pb="20px"
        >
          <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
            {locationId => (
              <CFView hover absolute top="50px">
                <CFLink href={locationMenus[locationId]}>
                  <CFImage
                    h="65px"
                    src={viewMenu}
                    alt="Togo Sushi View Menu Button"
                  />
                </CFLink>
              </CFView>
            )}
          </CFSelect>
          <CFImage src={mobileHero} w="100vw" alt="About" />
          <CFView column center>
            <CFView mb="10px">
              <OrderPickupButton />
            </CFView>
            <CFLink href="http://onelink.to/sw7ran">
              <CFImage src={downloadAppButton} maxWidth="300px" alt="About" />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="700px"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
          relative
        >
          <Header />
          <CFView h="100%" relative column justifyCenter alignEnd zIndex={-1}>
            <CFImage src={heroText} h="450px" mr="4%" alt="About" />
            <CFView relative right="13%" bottom="120px">
              <CFView mb="15px">
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
