import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { homebg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const images = []

const locationMenus = {
  Cdppq8wkAl0pYCCGrKCA:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/Cdppq8wkAl0pYCCGrKCA',
  Fhm09z7wYlt9dBuzI06y:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/Fhm09z7wYlt9dBuzI06y',
  TYe1YOOktKrvpckotSUu:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/TYe1YOOktKrvpckotSUu',
  ul2rsIuDM58PRwemR7tz:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/ul2rsIuDM58PRwemR7tz',
  z7xiBbF4M6It88nAuRWo:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/z7xiBbF4M6It88nAuRWo',
  zQ8ACADtoGCQ7WdYpwiK:
    'https://order.codefusion.tech/restaurants/DBjsC3ZPofOoJhcnzBjm/locations/zQ8ACADtoGCQ7WdYpwiK',
}

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          h="85px"
          image={`url(${homebg}) top / cover no-repeat`}
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
        >
          <CFView row center>
            <CFImage h="65px" mt="5px" src={logo} alt="Togo Sushi Logo" />
            <CFImage
              h="35px"
              ml="10px"
              mt="5px"
              src={logoTitle}
              alt="Togo Sushi Logo Title"
            />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="70px"
          image={`url(${homebg}) top / cover no-repeat`}
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
          zIndex={99}
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="50px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="160px"
                mt="15px"
                src={logo}
                alt="Togo Sushi Logo"
                zIndex={99}
              />
              <CFImage
                h="50px"
                mt="25px"
                ml="12px"
                src={logoTitle}
                alt="Togo Sushi Logo Title"
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="27px" ml="20px">
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
            <CFSelect
              selector={{
                locationId: dispatch.restaurant.getSelectedLocationId,
                pdfMenuUrl: dispatch.restaurant.getPdfMenuUrl,
              }}
            >
              {({ locationId, pdfMenuUrl }) => (
                <CFView hover mt="7px">
                  <CFLink
                    href={
                      locationId === 'lWoCbjf1UTpTpwoTv7Ij' ||
                      locationId === 'vjFb4cvIKMsQZJ3eHe9W'
                        ? pdfMenuUrl
                        : locationMenus[locationId]
                    }
                    newTab
                  >
                    <CFImage
                      h="65px"
                      src={viewMenu}
                      alt="Togo Sushi View Menu Button"
                    />
                  </CFLink>
                </CFView>
              )}
            </CFSelect>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
